import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { EDIT_WIKI_PAGE_MUTATION } from "queries/wiki";

import { extractMulti } from "helpers/ui/multiLang";

import { NarrowTwoColumnLayout } from "layout/twoColumnLayout";
import { Editor } from "./Editor";
import { WikiPage } from "generated/graphql";
import { useMultiLang } from "helpers/multiLang";

import classNames from "classnames";
import { Avatar } from "pages/newEmployee/avatar";
import { Link } from "wouter";

import { encodeSearchState } from "pages/search";

const scrollToIndex = (index: number) => {
  // Fetch only h1 or h2 headers (The ones that are used to build the sections link on the left)
  const allHeaders = [
    ...document.querySelectorAll(".ProseMirror h1, .ProseMirror h2"),
  ];
  const element = allHeaders?.[index];
  element?.scrollIntoView({
    behavior: "smooth",
    block: "center",
  });
};

const ContentSections = ({ content }) => {
  const { t } = useTranslation("wiki");

  const headerElements = document.createElement("html");
  headerElements.innerHTML = content;

  const headers = [...headerElements.querySelectorAll("h2")];
  const headersLength = Object.keys(headers).length;
  const headersContent = (
    <div>
      <h5 className="hidden mb-6 text-xl font-medium text-gray-350 md:flex">
        {t("tableOfContents")}
      </h5>
      {headers?.map((h, index) => {
        return (
          <div
            key={h.innerHTML}
            onClick={() => {
              scrollToIndex(index);
            }}
            className={classNames({
              "my-1 text-blue-500 cursor-pointer text-normal hover:text-gray-900 transition ease-in-out":
                h.tagName == "H2",
            })}
            // Doing this here should be safe, as the data we render here comes from the output of EditorJS.
            // The output already contains escaped HTML, so we don't need to escape it again.
            //
            // If we would directly render the text here, users would see the escaped text,
            // which contains HTML entities like &amp;, &lt;, &gt;, etc.
            dangerouslySetInnerHTML={{ __html: h.innerHTML }}
          />
        );
      })}
    </div>
  );
  return headersLength >= 2 ? headersContent : null;
};

const EmployeeShortProfile = ({ person, roles }) => {
  const { t } = useTranslation(["project", "abacusProjects"]);
  const firstRole = roles[0];

  return (
    <Link href={`/employee/${person.id}`}>
      <div className="cursor-pointer group flex flex-row items-stretch mb-2 transition ease-in-out hover:bg-opacity-5 hover:border-opacity-40 text-blue-500 hover:bg-blue-500 hover:border-blue-500 pr-2 py-2">
        <div className="flex flex-col justify-center">
          <Avatar
            size="small"
            url={person.colorProfilePicture ?? person.profilePicture}
          />
        </div>
        <div className="flex flex-col justify-center pl-4 pt-1 space-y-1">
          <span className="text-lg font-medium leading-4 text-blue-500 group-hover:text-gray-500 transition-transform ease-in-out">
            {person.firstName} {person.lastName}
          </span>
          <div className="text-sm leading-4 text-gray-350">
            <div>{firstRole ? firstRole : t("abacusProjects:role_0")}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export const Content = ({
  page,
  parentPage,
  slugs,
  readOnly = false,
}: {
  page: Partial<WikiPage>;
  parentPage?: Partial<WikiPage>;
  slugs: string[];
  readOnly?: boolean;
}) => {
  const { t, i18n } = useTranslation(["common", "wiki", "form"]);
  const m = useMultiLang();

  const startLanguage =
    i18n.language.length > 2 ? i18n.languages[1] : i18n.language;
  const [language, setLanguage] = useState<string>(startLanguage);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const ratioContentLength =
    (page?.contentHtml?.["de"]?.length ?? 1) /
    (page?.contentHtml?.[language]?.length ?? 1);
  const displayLanguageWarningBanner =
    language !== "de" && ratioContentLength > 10;

  const [editMutation] = useMutation(EDIT_WIKI_PAGE_MUTATION);
  const saveContent = (content, language) => {
    editMutation({
      variables: {
        id: page.id,
        fields: {
          contentHtml: extractMulti({
            ...(page.contentHtml ?? {}),
            [language]: content,
          }),
        },
      },
    });
  };

  return (
    <NarrowTwoColumnLayout
      leftContent={
        <div
          className="h-full sticky overflow-y-auto top-22.5"
          style={{ maxHeight: "calc(100vh - 200px)" }}
        >
          <div className="mb-4">
            <h5 className="hidden text-xl font-medium text-gray-350 md:flex">
              {t("wiki:whoToAsk")}
            </h5>
          </div>

          {(page.employeesToAsk ?? parentPage?.employeesToAsk ?? []).map(
            (employee) => {
              return (
                <EmployeeShortProfile
                  key={employee.id}
                  person={employee}
                  roles={employee.roles?.map((role: any) =>
                    m(role?.role?.name),
                  )}
                />
              );
            },
          )}

          {(page?.tags?.length ?? 0) > 0 ? (
            <div className="mt-8">
              <h5 className="hidden mb-6 text-xl font-medium text-gray-350 md:flex capitalize">
                {t("form:fieldName.wiki.tags")}
              </h5>
              <div className="flex flex-row flex-wrap pb-6 sm:pb-0 gap-x-2">
                {(page.tags ?? []).map((tag) => {
                  const filters = {
                    selectedTopFilter: "wikiPage",
                    selectedSubFilters: {},
                  };
                  const searchLink = encodeSearchState(
                    m(tag?.tag?.name),
                    filters,
                  );
                  return (
                    <Link key={tag.id} href={`/results?${searchLink}`}>
                      <label
                        key={tag.id}
                        className="cursor-pointer flex items-center h-auto px-2 py-1 mb-2 ml-0 text-sm transition ease-in-out text-blue-500 bg-blue-500 border-none rounded bg-opacity-10 hover:bg-blue-500 hover:bg-opacity-5 hover:border-blue-500 hover:border-opacity-40 cursor-pointer hover:text-gray-500"
                      >
                        {m(tag?.tag?.name)}
                      </label>
                    </Link>
                  );
                })}
              </div>
            </div>
          ) : null}

          <ul className="mt-8 mb-8">
            <ContentSections content={page?.contentHtml?.[language] ?? ""} />
          </ul>
        </div>
      }
      rightClassName=""
      rightContent={
        <div className="h-full">
          {displayLanguageWarningBanner ? (
            <div className="py-4 px-10 border border-gray-200 bg-gray-50">
              <div className="font-medium text-red-500">
                {t("common:warning")}
              </div>
              <div className="pt-1 text-sm">{t("wiki:germanOnly")}</div>
            </div>
          ) : null}
          <Editor
            slugs={slugs}
            language={language}
            setLanguage={setLanguage}
            content={page.contentHtml}
            onSave={(data, language) => {
              if (!readOnly) {
                saveContent(data, language);
              }
            }}
            readOnly={readOnly}
            page={page}
          />
        </div>
      }
    />
  );
};
